import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do you want to step in to the future before others?</h1>
    </div>

    {/* <div className="gpt3__footer-btn">
      <p>Request Early Access</p>
    </div> */}

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Sarthak Dhawan <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <a href='https://www.linkedin.com/in/sarthak-dhawan-5796901ab/' target={'_blank'}><p>LinkedIn - Sarthak</p></a>
        <a href='https://www.linkedin.com/in/satvik-duddukuru/' target={'_blank'}><p>LinkedIn - Satvik</p></a>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>quantcraft.app@gmail.com</p>
        <p>214-425-7160</p>
        <p>sarthak.dhawan.us@gmail.com</p>
        <p>10satvik@gmail.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 QuantCraft. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
