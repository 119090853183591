import React, { useEffect } from 'react';
import AOS from 'aos';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';
import 'aos/dist/aos.css';
import { BsYoutube } from 'react-icons/bs';

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content" data-aos="fade-right">
        <h1 className="gradient__text">Quantum Algorithms in Virtual Reality</h1>
        <p>The <i> first-ever </i> &#160;virtual reality quantum algorithm education platform. We use interactive Bloch Spheres as Qubits and 3D visualizations to build your quantum intuition.  </p>
        <div className="gpt3__header-content__input">
          {/* <input type="email" placeholder="Your Email Address" /> */}
          <a href="https://www.youtube.com/watch?v=bAkFwrJl_7Y" target="_blank"><button type="button" >Watch QuantCraft Showcase Video</button></a>
        </div>

        <div className="gpt3__header-content__people">
          <img src={people} />
          <p>Join 100+ students in the QuantCraft Community</p>
        </div>
      </div>

      <div className="gpt3__header-image" data-aos="fade-up">
        <img src={ai} />
      </div>
    </div>
  );
};

export default Header;
