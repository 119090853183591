import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wquantcraft">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is QuantCraft?" text="Most existing quantum education initiatives are targeted toward graduate students, often requiring an academic background in quantum mechanics and linear algebra. But that isn't going to cut it as we transition into a new quantum era. " />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The first VR quantum programming environment for all ages</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="3D Interactive Qubits" text="We use interactive 3D vectors in the Bloch Sphere to define Qubits, abstracting mathematical complexities. Anyone, any age, of any background can manipulate an arrow." />
      <Feature title="Gate Visualizations" text="Visualize 3D transformations from quantum gates with animations. View gate transformation for any unitary matrix." />
      <Feature title="Quantum Intuition" text={"Each level gradually increases in difficulty by introducing new quantum gates. These levels foster the development of what we call \"quantum intuition,\" where students instinctively think probabilistically rather than deterministically. "} />
    </div>
  </div>
);

export default WhatGPT3;
